import styled from "styled-components"

export const Wrap = styled.div``

export const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  width:800px;
  max-width:100%;
  margin:-20px auto 30px;
`

export const Partners = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  margin: 20px 0;
`

export const PhoneBtn = styled.a`
  min-width: 180px;
  padding: 0 8px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 3px;
  background: rgba(255,255,255,0.2);
  display: inline-block;
  line-height: 60px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  &:hover {
    background: rgba(255,255,255,0.3);
  }
`

export const Image = styled.div<{ src: String }>`
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 60px;
`
export const Html = styled.div`
  p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  strong {
    font-family: Font-Black;
  }
`
export const Info = styled.ul`
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 760px) {
    display: block;
  }
  li {
    width: 100%;
    &:last-child {
      margin-left: 20px;
      height: 300px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: 760px) {
        margin:20px 0 0 0;
      }
    }
  }
`

export const WorkingHours = styled.div`
  margin-top: 50px;
  p {
    font-size: 17px;
    line-height: normal;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    span {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`
